import { initFormHandling } from "./formHandling";

const counterUp = window.counterUp?.default;

const gentleWayWrapper = document.querySelector(".gentle-way-wrapper");
const membershipWrapper = document.querySelector(".membership-wrapper");

if (gentleWayWrapper) {
  const paragraphs = gentleWayWrapper.querySelectorAll("p");

  paragraphs.forEach((p, index) => {
    p.classList.add("text-animate");
    p.dataset.delay = index * 0.5;
  })
}

if (membershipWrapper) {
  const elements = membershipWrapper.querySelectorAll("& > *");

  elements?.forEach((element, index) => {
    element.classList.add("text-animate");
  })
}

function inViewport(element) {
  const rect = element.getBoundingClientRect();
  return rect.top <= window.innerHeight;
}

function topTouchesMiddle(element) {
    const rect = element.getBoundingClientRect();
    const middleOfScreen = window.innerHeight / 2;

    // Check if the top of the element is at or below the middle of the screen
    return rect.top <= middleOfScreen;
}

const countWrapper = document.querySelector(".count__grid");
const countValues = document.querySelectorAll(".count__grid h4");

let countExecuted = false;

const borders = document.querySelectorAll(".border");

const textItems = document.querySelectorAll(".text-animate");

let cards;

const infoCards = document.querySelectorAll(".goals-of-judo__cards__card__info");
const infoCardsAges = document.querySelectorAll(".all-ages__cards-wrapper__card__info");
const infoCardClasses = document.querySelectorAll(".first-class__content-wrapper__info");
const svgShapes = document.querySelectorAll(".first-class__content-wrapper svg");

cards = [...infoCards, ...infoCardsAges, ...infoCardClasses, ...svgShapes]

const handleBordersAnimation = () => {
    borders.forEach((border) => {
        if (topTouchesMiddle(border)) {
            border.classList.add("animated")
        } else {
            border.classList.remove("animated")
        }
    })
}

const countUpValues = () => {
  countValues.forEach((cv) => {
    counterUp(cv, {
      duration: 1700,
      delay: 12,
    });
  });
};

const menu = document.querySelector(".menu");
const menuResponsive = document.querySelector("header nav ul");

menu.addEventListener("click", function () {
  if (this.classList.contains("open")) {
    this.classList.remove("open");
  } else {
    this.classList.add("open");
  }

  if (menuResponsive.classList.contains("opened")) {
    menuResponsive.classList.remove("opened");
  } else {
    menuResponsive.classList.add("opened");
  }
});

const animateInfoCards = () => {
    cards.forEach((card) => {
        if (topTouchesMiddle(card)) {
            card.classList.add("animated")
        }
    })
}

// Attach the scroll event listener to the window
window.addEventListener("scroll", () => {
  if (countWrapper && inViewport(countWrapper) && !countExecuted) {
    countUpValues();
    countExecuted = true;
  }
  handleBordersAnimation();
  animateInfoCards();
  animateText();
});

const animateText = () => {
  textItems.forEach((textItem) => {
    
    if (inViewport(textItem) && !textItem.dataset.executed) {
      const delay = textItem.dataset.delay;
      textItem.dataset.executed = true;
      const textItemSplitType = new SplitType(textItem, { type: "lines" });

      gsap.fromTo(
        textItemSplitType.lines,
        {
          y: 100,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          stagger: 0.15,
          duration: 2,
          ease: "power4.out",
          delay: delay ?? 0,
        }
      );
    }

  });
};

animateText();


document.addEventListener("DOMContentLoaded", () => {
  initFormHandling();
})